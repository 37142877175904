import { DealComponent } from './home/deal/deal.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashmasterComponent } from './dashboardlayout/dashmaster/dashmaster.component';
import { AuthGuardService,HomeGuardService } from './services';


const routes: Routes = [
  {    path: '',  // redirectTo: 'dashboard',  // redirectTo: 'auth/login',
      //  redirectTo: 'home-sector',
      redirectTo: 'kfd',
       pathMatch: 'full'
  },
  {
    path: 'dashboard',// component:LayoutmasterComponent, 
    component:DashmasterComponent,
    // canActivate: [AuthGuardService],
    children:[{ 
        path: '', loadChildren: () => import('./homedashboard/homedashboard.module').then(m => m.HomedashboardModule) 
      }]
  },
  { 
    path: '',// component:LayoutmasterComponent, 
    component:DashmasterComponent,
    // canActivate: [AuthGuardService],
    children:[{ 
      path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) 
    }]
  },
  { 
    path: 'offerin',// component:LayoutmasterComponent, 
    component:DashmasterComponent,
    canActivate: [AuthGuardService],
    children:[
      { 
      path: '', loadChildren: () => import('./homeout/homeout.module').then(m => m.HomeoutModule) 
    }, 
    ]},
    { 
      path: '',
      //component:LayoutmasterComponent, 
       component:DashmasterComponent,
      //canActivate: [HomeGuardService],
      children:[
        { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) }, 
        { path: 'homepage', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) }, 
        // { path: 'homepage', loadChildren: () => import('./shome/shome.module').then(m => m.ShomeModule) }, 
      ]},
    { 
    path: '', 
    component:DashmasterComponent, 
    canActivate: [AuthGuardService],
    children:[ 
      { 
        path: '', 
        loadChildren: () => import('./route-file/route-file.module').then(m => m.RouteConfiqFileModule) 
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
		// Tell the router to use the HashLocationStrategy.
		useHash: false,
		enableTracing: false,
 
		// This will tell Angular to preload the lazy-loaded routes after the
		// application has been bootstrapped. This will extend to both top-level
		// and nested lazy-loaded modules.
		preloadingStrategy: PreloadAllModules
	})],
  exports: [RouterModule]
})

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
export class AppRoutingModule { }
