// //*for live api use uncomment============>>>>/*
export const environment = {
  production: false,
  apiUrl:'https://partnerapi.fareboutique.com/v1/',
  url:'https://partnerapi.fareboutique.com/',
  XAPIKEY:'1JZXVV1650360940112'
};


// //*for Demo api use uncomment============>>>>*/
// export const environment = {
//   production: false,
//   apiUrl:'https://demopartnerapi.fareboutique.com/v1/',
//   url:'https://demopartnerapi.fareboutique.com/'
// };


// /*******for use dev api****************
/************************************** */
// export const environment = {
//   production: false,
//   apiUrl:'https://devpatnerapi.fareboutique.com/v1/',
//   url:'https://devpatnerapi.fareboutique.com/',
//   XAPIKEY:'1FMQKB1639407126571'
// };
