import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CommonService } from 'src/app/services';
import { SharedserviceService } from 'src/app/services';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  formEmail: FormGroup;
  userdata:any;
  user:any;
  user_type:any;
  name:any;
  submitted: boolean = false;
  id:any;
  showlogin:boolean=true;
  parseuser:any;
  getData: any;
  ownerData: any;
  constructor(private router: Router,public commonService:CommonService,private fb: FormBuilder,public sharedService: SharedserviceService,private toastr: ToastrManager,) {
    this.userdata= JSON.parse(localStorage.getItem("userAgency"));
    // this.ownerData= JSON.parse(sessionStorage.getItem("ownerData"));

  }

  ngOnInit() {  

    this.contactDetail();
    this.formEmail = this.fb.group({
      email: ['',Validators.required],
      name: ['',Validators.required],
      subject: ['',Validators.required],
      message: ['',Validators.required],
      mobile_no: ['',Validators.required],
      company_name: ['New Company',Validators.required],
      // send_to_email:['sunil.fareboutique@gmail.com'],
      send_to_email:['kamlatravelslko@yahoo.com'],
    });

    this.user=JSON.parse(localStorage.getItem("user"));
    if(this.user){
      this.id = this.user.id;
      this.user_type = this.user.role_id;
    //console.log("usertype",this.user_type);
      this.showlogin=false;
      this.name = this.user.name;
    }    
    
   


  }

  get enq() { return this.formEmail.controls; }

  closeContact() {
    $('#enqFromGroup').modal('hide');
  }
  groupEnq1(){
    $('#enqFromGroup').modal('show');
  }
  enquiryForm(){
    this.submitted = true;
    if(this.formEmail.invalid){
      return
    }else{
    var requestData ={
      "name":this.formEmail.value.name,
      "email":this.formEmail.value.email,
      "subject":this.formEmail.value.subject,
      // "message": this.formEmail.value.message+" "+JSON.stringify(commentdata),
      "message": this.formEmail.value.message,
      "company_name": this.formEmail.value.company_name,
      "mobile_no": this.formEmail.value.mobile_no.toString(),
      // "send_to_email":"sunil.fareboutique@gmail.com"
      "send_to_email":"kamlatravelslko@yahoo.com"
    }
  
    this.sharedService.post('v1/pages/contact_us',requestData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        $('#enqFromGroup').modal('hide');
        this.formEmail.reset();
        var contactData = res.data;
        console.log("::::::::::::::",contactData);
        this.toastr.successToastr(res.replyMsg, 'Success');
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
    }
  }



  profile(id){
    this.router.navigateByUrl("/dashboard/viewprofile");
   
  }
  logout() {
    this.showlogin=true;
    this.commonService.onLogout();
  }  
  contactDetail() {
    const self = this;
    self.sharedService.get('v1/users/get_website_owner_data').subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.getData = res.data;
        console.log("::::::::::::::",this.getData);
        sessionStorage.setItem("ownerData", JSON.stringify(this.getData) );
      } else {
        
      }
    });
  }

}
